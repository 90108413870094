const getDate = item => {
  let date = item.publishDate;
  if (!date) {
    date = item._updatedAt.split("T")[0];
  }

  return date;
};

const mergePosts = ({ articles, resource, resources, events }) => {
  const posts =
    resource && articles
      ? [...articles.nodes, ...resource.nodes]
      : [...resources.nodes];

  posts.map(item => {
    item.date = getDate(item);
    return item;
  });
  posts.sort((a, b) => {
    return b.date.localeCompare(a.date);
  });
  return posts;
};

export { mergePosts };
