import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import PostsList from "../components/posts/list";
import { mergePosts } from "../util/posts";

export const query = graphql`
  query ResourcesQuery {
    page: allSanityPageNewResources {
      nodes {
        title
        subtitle
      }
    }

    resources: allSanityResources {
      nodes {
        ...resourcesTileFields
      }
    }
  }
`;
const NewResources = props => {
  const page = props.data.page.nodes[0];
  const filteredPosts = mergePosts(props.data);
  // console.log(filteredPosts);

  return (
    <Layout>
      <SEO title={page.title} description={page.subtitle} />
      <Hero title={page.title} description={page.subtitle}></Hero>
      <div className="container">
        {filteredPosts.length !== 0 ? (
          <PostsList posts={filteredPosts} condensed={true} />
        ) : (
          <div className="no-results">No results for your current filters.</div>
        )}
      </div>
    </Layout>
  );
};
export default NewResources;
